/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

input {
  outline: none; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

input[type=number] {
  -moz-appearance: textfield; }

* {
  box-sizing: border-box;
  outline: none; }

.nav-btn span {
  display: block; }

button, a {
  cursor: pointer;
  transition: all .3s ease;
  border: none;
  background: none;
  text-decoration: none; }
  button:hover, a:hover {
    opacity: 0.7; }

button {
  font-family: "Montserrat", sans-serif; }

textarea {
  resize: vertical;
  font-family: "Montserrat", sans-serif; }

input, textarea {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #1D1D1F; }
  input::placeholder, textarea::placeholder {
    font-family: "Montserrat", sans-serif;
    opacity: 1; }

body {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #1D1D1F;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-x: hidden; }

.wrapper {
  max-width: 1220px;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  margin-left: auto;
  margin-right: auto; }
  .wrapper--xs {
    max-width: 990px; }
  .wrapper.ui-wrapper {
    max-width: 100%; }

main {
  flex: 1;
  padding-bottom: 96px;
  overflow-x: hidden; }

img {
  display: block;
  max-width: 100%; }

.main {
  padding-top: 40px;
  padding-bottom: 0; }

.bradcrumbs {
  margin-bottom: 30px; }

.table-tabs {
  display: flex; }

.bradcrumbs ul {
  display: flex; }
  .bradcrumbs ul li a {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #009FCD; }
  .bradcrumbs ul li span {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #B1B1B1; }
  .bradcrumbs ul li:not(:first-child) {
    position: relative; }
    .bradcrumbs ul li:not(:first-child):before {
      content: "/";
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #009FCD;
      margin: 0 10px; }
  .bradcrumbs ul li:last-child:before {
    color: #B1B1B1; }

.page-title {
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  color: #333333;
  margin-bottom: 60px; }

.cb-section {
  padding-top: 40px;
  padding-bottom: 0px;
  border: 1px solid rgba(0, 0, 0, 0.1); }
  .cb-section .wrapper {
    display: grid;
    grid-template-columns: 480px 1fr;
    grid-column-gap: 5px; }
  .cb-section .col-text {
    padding-top: 43px;
    padding-bottom: 43px; }
    .cb-section .col-text h2 {
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      color: #333333;
      margin-bottom: 30px; }
    .cb-section .col-text p {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #333333; }
    .cb-section .col-text .btn {
      margin-top: 50px; }

.primery-btn {
  padding: 15px 30px;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  background: #009FCD;
  border-radius: 10px; }
  .primery-btn:hover {
    opacity: 0.8; }

.main-footer {
  background: #F8F8F8;
  padding-top: 60px;
  padding-bottom: 40px; }

.footer-nav-col {
  margin-right: 60px; }
  .footer-nav-col li + li {
    margin-top: 37px; }
  .footer-nav-col li a {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #333333; }

.footer-nav {
  display: flex; }

.footer-contacts {
  margin-top: 60px; }
  .footer-contacts p {
    display: flex;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #333333;
    align-items: center; }
    .footer-contacts p a {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #333333; }
    .footer-contacts p img {
      margin-right: 20px; }
    .footer-contacts p + p {
      margin-top: 20px; }

.copy {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 60px; }
  .copy p {
    max-width: 680px; }
  .copy p,
  .copy span {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #333333;
    opacity: 0.5; }

.cabinet-grid {
  display: grid;
  grid-template-columns: 281px 1fr;
  grid-column-gap: 50px; }

.person-photo {
  margin-bottom: 30px;
  width: 180px;
  height: 180px;
  border-radius: 50%; }
  .person-photo img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.person-block {
  padding-bottom: 30px;
  border-bottom: 1px solid #E0E0E0; }
  .person-block + .person-block {
    margin-top: 30px; }

.person-name {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #333333; }

.person-information-row + .person-information-row {
  margin-top: 30px; }

.person-information-row span {
  display: block;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #333333;
  opacity: 0.5;
  margin-bottom: 10px; }

.person-information-row strong,
.person-information-row strong a {
  display: block;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #333333; }

.row-btn {
  margin-top: 30px;
  padding: 0;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px; }
  .row-btn img {
    margin-right: 16px; }
  .row-btn:hover {
    opacity: 0.8; }

.input-wrap.ic.date {
  position: relative; }
  .input-wrap.ic.date:after {
    content: "";
    width: 20px;
    height: 20px;
    background-image: url("../img/date.svg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 12px; }

.redact {
  color: #009FCD; }

.out {
  color: #F10000; }

.person-info {
  padding-bottom: 30px; }

.table-container {
  filter: drop-shadow(0px 6px 24px rgba(0, 0, 0, 0.12));
  overflow: hidden;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer; }

.table-tab {
  padding: 20px 30px;
  background: #FFFFFF;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #333333;
  opacity: 0.6;
  white-space: nowrap;
  cursor: pointer;
  filter: drop-shadow(0px 6px 24px rgba(0, 0, 0, 0.12));
  clip-path: polygon(0 0, 95% 0, 100% 100%, 0 100%); }
  .table-tab:not(:first-child) {
    margin-left: -16px; }
  .table-tab.active {
    color: #333333;
    opacity: 1; }

.table {
  background-color: #fff; }

.table-head {
  padding: 28px 18px 20px 18px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); }

.table-footer {
  padding: 30px; }

.table-pagination {
  display: flex; }
  .table-pagination .b + .b {
    margin-left: 10px; }
  .table-pagination .b {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #333333;
    background: #F8F8F8;
    border-radius: 10px;
    width: 44px;
    height: 44px; }
    .table-pagination .b:not(.active) {
      opacity: 0.7; }
    .table-pagination .b.active {
      background: none; }
  .table-pagination span.b {
    background: none; }

.table-pagination-navs {
  display: flex; }
  .table-pagination-navs .nav + .nav {
    margin-left: 10px; }
  .table-pagination-navs .nav {
    width: 44px;
    height: 44px;
    background: #009FCD;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    cursor: pointer; }
    .table-pagination-navs .nav:not(.active) {
      opacity: 0.7; }
    .table-pagination-navs .nav.active {
      opacity: 0.3; }

.table-footer {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.table table {
  width: 100%; }
  .table table tr {
    border-bottom: 1px solid rgba(51, 51, 51, 0.1); }
  .table table td {
    width: 25%; }
  .table table tr td:first-child {
    padding-left: 18px; }
  .table table tr td:last-child {
    padding-right: 18px; }

.t-col {
  display: flex;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 19px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #333333; }
  .t-col a {
    color: #009FCD;
    max-width: 182px;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .t-col span {
    color: #B1B1B1;
    margin-left: 4px; }

.table .thead {
  border-bottom: 1px solid rgba(51, 51, 51, 0.1); }
  .table .thead .tr {
    display: grid;
    grid-template-columns: repeat(4, 25%); }
    .table .thead .tr .td {
      display: flex;
      align-items: center; }
    .table .thead .tr .td:first-child {
      padding-left: 18px; }
    .table .thead .tr .td:last-child {
      padding-right: 18px; }

.table .thead .td {
  padding-top: 28px;
  padding-bottom: 20px; }
  .table .thead .td span {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: rgba(51, 51, 51, 0.6); }
  .table .thead .td .t-col {
    padding-top: 0;
    padding-bottom: 0; }

.n-select,
.input-wrap input {
  height: 44px;
  background: #F8F8F8;
  border-radius: 10px;
  float: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: rgba(51, 51, 51, 0.6);
  display: flex;
  align-items: center;
  padding-left: 12px;
  padding-right: 40px;
  border: none;
  opacity: 1; }
  .n-select span,
  .input-wrap input span {
    font-size: 14px !important;
    line-height: 18px !important;
    margin-left: 0;
    color: rgba(51, 51, 51, 0.6); }

.input-wrap input {
  width: fit-content;
  width: 87px; }

.input-wrap input::placeholder {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: rgba(51, 51, 51, 0.6);
  font-family: "Montserrat", sans-serif; }

.table-tab-content:not(.active) {
  display: none; }

.cabinet-grid {
  padding-bottom: 100px; }

.nice-select:after {
  right: 20px;
  top: 47%;
  width: 8px;
  height: 8px; }

.collapse-btn {
  display: flex;
  align-items: center;
  padding: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #009FCD;
  margin-bottom: 10px;
  display: none; }
  .collapse-btn svg {
    margin-right: 10px;
    transform: rotate(-180deg); }
  .collapse-btn.active {
    margin-bottom: 20px; }
    .collapse-btn.active svg {
      transform: rotate(0); }

@media (max-width: 1024px) {
  .bradcrumbs ul li:not(:first-child):before {
    margin: 0 8px; }
  .page-title {
    font-size: 32px;
    line-height: 39px;
    margin-bottom: 50px; }
  .cabinet-grid {
    grid-template-columns: 100%;
    grid-row-gap: 20px; }
  .person-info {
    display: grid;
    grid-template-columns: 180px 1fr;
    grid-column-gap: 40px; }
  .person-block {
    padding-bottom: 18px; }
  .person-block + .person-block {
    margin-top: 20px; }
  .person-information-rows {
    display: flex;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 30px; }
  .person-information-row + .person-information-row {
    margin-top: 0; }
  .person-information-row:nth-child(1) {
    order: 1; }
  .person-information-row:nth-child(2) {
    order: 3; }
  .person-information-row:nth-child(4) {
    order: 4; }
  .person-information-row:nth-child(3) {
    order: 2; }
  .row-btn {
    margin-top: 40px; }
  .row-btn.out {
    margin-top: 21px; }
  .cabinet-grid {
    padding-bottom: 58px; }
  .cb-section .wrapper {
    display: grid;
    grid-template-columns: 315px 1fr;
    grid-column-gap: 40px; }
  .cb-section {
    padding-top: 50px; }
  .cb-section .col-text {
    padding-top: 12px;
    padding-bottom: 43px; }
  .main-footer {
    padding-top: 36px; }
  .footer-nav-col {
    margin-right: 40px; }
  .footer-contacts {
    margin-top: 48px; }
  .footer-contacts p + p {
    margin-top: 15px; }
  .copy {
    margin-top: 50px;
    align-items: flex-end; }
  .copy p {
    max-width: 482px; }
  .copy span {
    font-size: 12px;
    line-height: 18px;
    text-align: right; }
  .n-select,
  .input-wrap input {
    height: 36px;
    padding-left: 10px;
    padding-right: 32px;
    font-size: 12px;
    line-height: 16px;
    min-width: 74px; }
    .n-select::placeholder,
    .input-wrap input::placeholder {
      font-size: 12px;
      line-height: 16px; }
    .n-select span,
    .input-wrap input span {
      font-size: 12px !important;
      line-height: 16px !important; }
  .table .thead .td {
    padding-top: 18px;
    padding-bottom: 20px; }
  .input-wrap input {
    width: 74px; }
  .nice-select:after {
    right: 16px;
    top: 47%;
    width: 6px;
    height: 6px; }
  .t-col {
    font-size: 12px;
    line-height: 16px;
    padding-top: 15px;
    padding-bottom: 14px; }
  .table-footer {
    padding: 20px 30px 30px; }
  .cb-section .wrapper {
    grid-template-columns: 100%; }
  .cb-section .col-text {
    padding-top: 12px;
    padding-bottom: 0;
    max-width: 375px; }
  .footer-nav {
    flex-wrap: wrap;
    margin-top: -25px; }
  .footer-nav-col,
  .footer-nav-col li + li {
    margin-top: 25px; }
  .copy {
    margin-top: 39px;
    align-items: center; }
  .copy p {
    max-width: 354px; } }

.table-tabs-scroll .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  opacity: 0 !important; }

.table-tabs-scroll .mCSB_horizontal.mCSB_inside > .mCSB_container {
  margin-bottom: 0; }

.table-scroll-container .mCSB_horizontal.mCSB_inside > .mCSB_container {
  margin-bottom: 0; }

.input-row {
  display: flex;
  align-items: center; }
  .input-row label {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #333333;
    cursor: pointer; }

.check-box {
  min-width: 15px;
  width: 15px;
  height: 15px;
  margin-right: 10.5px;
  position: relative; }
  .check-box input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    cursor: pointer;
    opacity: 0;
    margin: 0; }
    .check-box input:checked ~ .mask {
      background: #009FCD;
      border-color: #009FCD; }
      .check-box input:checked ~ .mask svg {
        opacity: 1; }
  .check-box .mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #B1B1B1;
    display: flex;
    align-items: center;
    justify-content: center; }
    .check-box .mask svg {
      opacity: 0; }

.c-select {
  position: relative; }

.c-select-body {
  position: absolute;
  top: calc(100% + 10px);
  background: #FFFFFF;
  box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  z-index: 2;
  max-height: 184px;
  padding: 14.5px;
  min-width: 182px;
  padding-right: 4px;
  display: none; }
  .c-select-body .option + .option {
    margin-top: 13px; }
  .c-select-body .mCSB_dragger_bar {
    background: #F8F8F8 !important;
    border-radius: 100px !important;
    width: 4px !important; }
  .c-select-body .mCSB_scrollTools .mCSB_draggerRail {
    background: none !important; }
  .c-select-body .mCSB_inside > .mCSB_container {
    margin-right: 0 !important; }
  .c-select-body .mCSB_scrollTools {
    width: 4px !important; }

.c-select-head {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  height: 44px;
  background: #F8F8F8;
  border-radius: 10px;
  cursor: pointer; }
  .c-select-head .current {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #333333;
    opacity: 0.6;
    margin-left: 0; }
  .c-select-head svg {
    margin-left: 8px; }

.c-select.active .c-select-head {
  background: rgba(0, 159, 205, 0.1); }
  .c-select.active .c-select-head .current {
    color: #009FCD;
    opacity: 1; }
  .c-select.active .c-select-head svg path {
    stroke: #009FCD; }
  .c-select.active .c-select-head svg {
    transform: rotate(180deg); }

.c-select.active .c-select-body {
  display: block; }

.c-select-head.filled {
  background: rgba(0, 159, 205, 0.1); }
  .c-select-head.filled .current {
    color: #009FCD;
    opacity: 1; }
  .c-select-head.filled svg path {
    stroke: #009FCD; }

.date.filled input {
  background: rgba(0, 159, 205, 0.1);
  color: #009FCD;
  width: 177px; }

.input-wrap.ic.date.filled:after {
  background-image: url(../img/date-b.svg); }

@media (min-width: 640px) {
  .collapse-content {
    display: block !important; } }

@media (max-width: 640px) {
  .person-info {
    grid-template-columns: 100%; }
  .date.filled input {
    width: 100%;
    padding: 0 10px; }
  .input-wrap.ic.date:after {
    right: 10px; }
  .c-select-body {
    width: 100%; }
  .c-select {
    width: 100%; }
  .c-select-head {
    justify-content: space-between;
    padding: 10px;
    height: 36px; }
    .c-select-head .current {
      font-size: 12px;
      line-height: 16px; }
  .collapse-btn {
    display: flex; }
  .person-information-rows {
    grid-template-columns: 100%;
    grid-row-gap: 20px; }
  .table-pagination .b:nth-child(3),
  .table-pagination .b:nth-child(4) {
    display: none; }
  .table .thead .tr {
    grid-template-columns: 100%;
    grid-row-gap: 10px; }
  .table .thead .td .t-col,
  .n-select,
  .input-wrap,
  .input-wrap input {
    width: 100%; }
  .table .thead {
    padding-top: 20px;
    padding-bottom: 32px; }
  .table .thead .tr .td {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 0;
    padding-bottom: 0; }
    .table .thead .tr .td:last-child {
      display: none; }
  .table table td:not(:first-child) {
    padding-left: 10px; }
  .table table {
    width: 580px; }
  body .table-scroll-container .mCustomScrollBox {
    padding-top: 20px; }
  .table .thead {
    border-bottom: none; }
  .table table tr:first-child {
    border-top: 1px solid rgba(51, 51, 51, 0.1); }
  body .table-scroll-container .mCSB_scrollTools.mCSB_scrollTools_horizontal {
    top: 0;
    height: 4px; }
  body .table-scroll-container .mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
    display: none; }
  body .table-scroll-container .mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_draggerRail {
    height: 4px;
    background: #E0E0E0;
    border-radius: 100px;
    margin: 0; }
  .table-scroll-container .mCSB_scrollTools .mCSB_draggerContainer {
    max-width: calc(100% - 40px);
    left: 20px; }
  .table-container {
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-right: -20px; }
  .bradcrumbs {
    margin-bottom: 20px; }
  .bradcrumbs ul li a,
  .bradcrumbs ul li span {
    font-size: 10px;
    line-height: 14px; }
  .page-title {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 30px; }
  .person-name {
    font-size: 16px;
    line-height: 22px; }
  .person-information-row {
    order: initial !important; }
  .row-btn {
    font-size: 14px;
    line-height: 18px; }
  .row-btn img {
    margin-right: 8px;
    width: 21px; }
  .row-btn {
    margin-top: 27px; }
  .person-info {
    padding-bottom: 0; }
  .table-tab {
    padding: 15px 20px;
    font-size: 12px;
    line-height: 16px; }
  .table-tab:not(:first-child) {
    margin-left: -10px; }
  .table .thead {
    padding-bottom: 23px; }
  body .table-scroll-container .mCustomScrollBox {
    padding-top: 7px; }
  .person-info {
    padding-bottom: 21px; }
  .t-col {
    padding-top: 12px;
    padding-bottom: 11px; }
  .table-footer {
    padding: 20px; }
  .table-pagination .b + .b {
    margin-left: 6px; }
  .cb-section .col-text h2 {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 20px;
    letter-spacing: 0; }
  .cb-section .col-text {
    padding-top: 0; }
  .cb-section {
    padding-top: 40px; }
  .cb-section .col-text p {
    font-size: 14px;
    line-height: 20px; }
  .cb-section .col-text .btn {
    margin-top: 40px;
    font-size: 16px;
    line-height: 24px;
    width: 100%; }
  .col-img {
    width: calc(100% + 150px);
    margin-left: -75px;
    margin-right: -75px;
    margin-top: 40px; }
  .footer-nav-col {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    margin-right: 0;
    width: 100%;
    grid-row-gap: 20px; }
    .footer-nav-col + .footer-nav-col {
      margin-top: 20px; }
  .footer-nav-col li + li {
    margin-top: 0; }
  .main-footer {
    padding-top: 48px; }
  .footer-contacts p,
  .footer-contacts p a {
    font-size: 12px;
    line-height: 18px;
    align-items: flex-start; }
  .footer-contacts {
    margin-top: 38px; }
  .footer-contacts p img {
    margin-right: 8px;
    /* margin-top: -5px; */
    width: 21px; }
  .copy {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start; }
  .copy span {
    text-align: left;
    margin-top: 40px; } }
