// Fonts

$defaultFont: 'Montserrat', sans-serif;


//Colors
$accent: #4052F2;



$tb: '1200px';

$tb2: '1050px';

$mb: '768px';