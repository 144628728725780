
input {outline: none;}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}


* {
  box-sizing: border-box;
  outline: none;
}

.nav-btn span {
  display: block;
}

button, a {
  cursor: pointer;
  transition: all .3s ease;
  border: none;
  background: none;
  text-decoration: none;

  &:hover {
    opacity: 0.7;
  }
}

button {
  font-family: $defaultFont;
}

textarea {
  resize: vertical;
  font-family: $defaultFont;
}

input, textarea {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #1D1D1F;

  &::placeholder {
    font-family: $defaultFont;
    opacity: 1;
  }
  
}

body {
  font-family: $defaultFont;
  font-size: 14px;  
  line-height: 20px;
  color: #1D1D1F;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

.wrapper {
  max-width: 1220px;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  &--xs {
    max-width: 990px;
  }

  &.ui-wrapper {
    max-width: 100%;
  }

 }

main {
  flex: 1;
  padding-bottom: 96px;
  overflow-x: hidden;
}

img {
  display: block;
    max-width: 100%;
}

